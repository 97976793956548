import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import trim from "lodash/trim";
import get from "lodash/get";
import forEach from "lodash/forEach";
import map from "lodash/map";
import {
  FlexVertical,
  FlexHorizontal,
  UserInput,
  UserTextarea,
  UserCarrotButton,
  UserDatePicker,
  SelectDropdown,
} from "../../../components/Common";
import { LabelInput, SelectButton, SpinLoader } from "./CareerDetails.styles";
import { statusAlert } from "../../../libs";
import { useParams } from "react-router-dom";
import { CareersActions } from "../../../actions";
import countries from "../Contact/Country";
import dayjs from "dayjs";

interface ISectionProps {
  specific: string;
}

interface ISelectButtonStructure {
  onChange(file: File): void;
  file: File | null;
}

const defaultUnspecificData = {
  name: "",
  surname: "",
  email: "",
  phone: "",
  address: "",
  coverLetter: "",
};

const defaultSpecificData = {
  date: null,
  country: "",
  name: "",
  surname: "",

  birthDate: null,
  age: "",
  sex: "",

  birthPlace: "",
  telephone: "",
  cell: "",
  source: "",
  email: "",

  currentLocation: "",
  education: "",
  faculty: "",
  languages: "",
  employment: "",
  location1: "",
  location2: "",
  location3: "",
  location4: "",
  location5: "",
  location6: "",
  experience: "",
};

const SelectFileButton: React.FC<ISelectButtonStructure> = ({
  onChange,
  file,
}) => {
  const { t } = useTranslation("user");
  const inputRef = React.useRef<HTMLInputElement>(null);
  const [filename, setFilename] = React.useState<string>("");

  React.useEffect(() => {
    if (!file) setFilename("");
    else {
      const getFilename = file.name;
      setFilename(getFilename);
    }
  }, [file]);

  const onChangeFile = (e: any) => {
    const fileLength = e.target.files.length;
    if (fileLength === 1) {
      const getFile: File = e.target.files[0];
      onChange(getFile);
    }
  };

  return (
    <FlexHorizontal alignCenter justifyFlexEnd style={{ height: "40px" }}>
      <SelectButton
        onClick={() => inputRef && inputRef.current && inputRef.current.click()}
      >
        <label htmlFor="">{filename || t("button.selectFile")}</label>
        <input
          type="file"
          accept=".pdf,.doc,.docx"
          ref={inputRef}
          onChange={(e: any) => onChangeFile(e)}
        />
        <i className="fas fa-file"></i>
      </SelectButton>
    </FlexHorizontal>
  );
};

const { applyCareer } = CareersActions;

const ApplyCareer: React.FC<ISectionProps> = ({ specific }) => {
  const { t } = useTranslation("user");
  const params = useParams();
  const dispatch = useDispatch();
  const requestIsLoading = useSelector((state) =>
    get(state, "Careers.applying")
  );
  const [mailDetails, setMailDetails] = React.useState<any>(
    defaultUnspecificData
  );
  const [specificData, setSpecificData] =
    React.useState<any>(defaultSpecificData);
  const [cv, setCV] = React.useState<File | null>(null);
  const [countryOptions, setCountryOptions] = React.useState<any[]>([]);

  React.useEffect(() => {
    const resolveCountryOptions: any[] = [];
    forEach(
      countries,
      (country: { name: string; dial_code: string; code: string }) => {
        resolveCountryOptions.push({
          label: country.name,
          value: country.name,
        });
      }
    );
    setCountryOptions(resolveCountryOptions);
  }, []);

  const checkEmptyField = () => {
    const dataCheck = [];

    if (specific === 'specific') {
      dataCheck.push(
        ...[
          // {
          //   label: t("label.date"),
          //   value: trim(get(specificData, "date")),
          // },
          {
            label: t("label.country"),
            value: trim(get(specificData, "country")),
          },
          {
            label: t("label.name"),
            value: trim(get(specificData, "name")),
          },
          {
            label: t("label.surname"),
            value: trim(get(specificData, "surname")),
          },

          {
            label: t("label.birthDate"),
            value: trim(get(specificData, "birthDate")),
          },
          {
            label: t("label.age"),
            value: trim(get(specificData, "age")),
          },

          {
            label: t("label.sex"),
            value: trim(get(specificData, "sex")),
          },
          {
            label: t("label.birthPlace"),
            value: trim(get(specificData, "birthPlace")),
          },
          {
            label: t("label.phoneNumber"),
            value: trim(get(specificData, "cell")),
          },
          {
            label: t("label.email"),
            value: trim(get(specificData, "email")),
          },
          {
            label: t("label.currentLocation"),
            value: trim(get(specificData, "currentLocation")),
          },
          {
            label: t("label.education"),
            value: trim(get(specificData, "education")),
          },
          {
            label: t("label.faculty"),
            value: trim(get(specificData, "faculty")),
          },
          {
            label: t("label.languages"),
            value: trim(get(specificData, "languages")),
          },
          {
            label: t("label.employment"),
            value: trim(get(specificData, "employment")),
          },
          {
            label: `${t("label.location")} 1`,
            value: trim(get(specificData, "location1")),
          },
          {
            label: t("label.experiences"),
            value: trim(get(specificData, "experience")),
          },
          {
            label: t("label.source"),
            value: trim(get(specificData, "source")),
          },
        ]
      );
    } else if (specific === 'unspecific') {
      dataCheck.push(
        ...[
          {
            label: t("label.name"),
            value: trim(get(mailDetails, "name")),
          },
          {
            label: t("label.surname"),
            value: trim(get(mailDetails, "surname")),
          },
          {
            label: t("label.email"),
            value: trim(get(mailDetails, "email")),
          },
          {
            label: t("label.phoneNumber"),
            value: trim(get(mailDetails, "phone")),
          },
          {
            label: "CV",
            value: cv,
          },
        ]
      );
    }

    let message = "";
    let flag = false;
    map(dataCheck, (item) => {
      if (!item.value) {
        flag = true;
        message = message + `\n${item.label}`;
      }
    });
    return {
      flag,
      message,
    };
  };

  const checkTypeField = () => {
    let flagType = false;
    const emailPattern =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const mobilePattern = /^([0-9]{1,15})$/;
    const numberPattern = /^([0-9]{1,15})$/;
    let messageType = `${t("alert.foundWrongTypes")}`;

    if (specific) {
      const isValidEmail = emailPattern.test(get(specificData, "email"));
      // const isValidPhone = mobilePattern.test(get(specificData, "telephone"));
      const isValidCell = mobilePattern.test(get(specificData, "cell"));
      const isValidAge = numberPattern.test(get(specificData, "age"));
      if (!isValidEmail) {
        flagType = true;
        messageType += `\n${t("label.email")}`;
      }
      // if (!isValidPhone) {
      //   flagType = true;
      //   messageType += `\n${t("label.telFix")}`;
      // }
      if (!isValidCell) {
        flagType = true;
        messageType += `\n${t("label.phoneNumber")}`;
      }
      if (!isValidAge) {
        flagType = true;
        messageType += `\n${t("label.age")}`;
      }
      if (
        (!trim(get(specificData, "email"))) ||
        (!trim(get(specificData, "cell")) && !trim(get(specificData, "age")))
      )
        return {
          messageType: "",
          flagType,
        };
    } else {
      const isValidEmail = emailPattern.test(get(mailDetails, "email"));
      const isValidPhone = mobilePattern.test(get(mailDetails, "phone"));
      if (!isValidEmail) {
        flagType = true;
        messageType += `\n${t("label.email")}`;
      }
      if (!isValidPhone) {
        flagType = true;
        messageType += `\n${t("label.phoneNumber")}`;
      }
      if (!trim(get(mailDetails, "email")) && !trim(get(mailDetails, "phone")))
        return {
          messageType: "",
          flagType,
        };
    }

    return { messageType, flagType };
  };

  // Handle change input
  const onChangeInput = (value: string, key: string) => {
    const data = {
      ...mailDetails,
      [key]: value,
    };
    setMailDetails(data);
  };

  const onChangeInputSpecific = (value: string, key: string) => {
    const data = {
      ...specificData,
      [key]: value,
    };
    setSpecificData(data);
  };

  const onSubmit = async () => {
    const { messageType, flagType } = checkTypeField();
    const { flag, message } = checkEmptyField();
    const currentDate = dayjs();
    const formattedCurrentDate = currentDate.format('D MMMM YYYY');
    if (flag || flagType)
      return await statusAlert(
        "warning",
        `${flag ? `${t("alert.foundEmptyFields")}: ${message}` : ""}\n${flagType ? messageType : ""
        }`
      );
    else {
      const formData = new FormData();
      const id = get(params, "id");
      if (id) formData.append("career", id);

      if (specific) {
        formData.append("date", trim(formattedCurrentDate));
        formData.append("country", trim(specificData.country));
        formData.append("name", trim(specificData.name));
        formData.append("surname", trim(specificData.surname));
        formData.append("birthDate", trim(specificData.birthDate));
        formData.append("age", trim(specificData.age));
        formData.append("sex", trim(specificData.sex));
        formData.append("birthPlace", trim(specificData.birthPlace));
        // formData.append("telephone", trim(specificData.telephone));
        formData.append("cell", trim(specificData.cell));
        formData.append("source", trim(specificData.source));
        formData.append("email", trim(specificData.email));
        formData.append("currentLocation", trim(specificData.currentLocation));
        formData.append("education", trim(specificData.education));
        formData.append("faculty", trim(specificData.faculty));
        formData.append("languages", trim(specificData.languages));
        formData.append("employment", trim(specificData.employment));
        formData.append("location1", trim(specificData.location1));
        formData.append("location2", trim(specificData.location2));
        formData.append("location3", trim(specificData.location3));
        formData.append("location4", trim(specificData.location4));
        formData.append("location5", trim(specificData.location5));
        formData.append("location6", trim(specificData.location6));
        formData.append("experience", trim(specificData.experience));
        formData.append("specific", `${true}`);
      } else {
        formData.append("name", trim(mailDetails.name));
        formData.append("surname", trim(mailDetails.surname));
        formData.append("email", trim(mailDetails.email));
        formData.append("phone", trim(mailDetails.phone));
        formData.append("address", trim(mailDetails.address));
        formData.append("coverLetter", trim(mailDetails.coverLetter));
        if (cv) formData.append("cv[]", cv);
        formData.append("unspecific", `${true}`);
      }

      await dispatch(applyCareer(formData));
      setMailDetails(defaultUnspecificData);
      setSpecificData(defaultSpecificData);
      setCV(null);
    }
  };

  const _renderUnspecificForm = () => {
    return (
      <FlexVertical flex="1" className="mt-1">
        <FlexHorizontal alignFlexStart>
          <LabelInput style={{ width: "150px", fontSize: "16px" }}>
            {t("label.name")} <span style={{ color: "red" }}>*</span>
          </LabelInput>
          <UserInput
            value={get(mailDetails, "name")}
            onChange={(e: any) => onChangeInput(e.target.value, "name")}
          />
        </FlexHorizontal>
        <FlexHorizontal alignFlexStart className="mt-1">
          <LabelInput style={{ width: "150px", fontSize: "16px" }}>
            {t("label.surname")} <span style={{ color: "red" }}>*</span>
          </LabelInput>
          <UserInput
            value={get(mailDetails, "surname")}
            onChange={(e: any) => onChangeInput(e.target.value, "surname")}
          />
        </FlexHorizontal>
        <FlexHorizontal alignFlexStart className="mt-1">
          <LabelInput style={{ width: "150px", fontSize: "16px" }}>
            {t("label.email")} <span style={{ color: "red" }}>*</span>
          </LabelInput>
          <UserInput
            value={get(mailDetails, "email")}
            onChange={(e: any) => onChangeInput(e.target.value, "email")}
          />
        </FlexHorizontal>
        <FlexHorizontal alignFlexStart className="mt-1">
          <LabelInput style={{ width: "150px", fontSize: "16px" }}>
            {t("label.phoneNumber")} <span style={{ color: "red" }}>*</span>
          </LabelInput>
          <UserInput
            value={get(mailDetails, "phone")}
            onChange={(e: any) => onChangeInput(e.target.value, "phone")}
            height="100%"
          />
        </FlexHorizontal>
        <FlexHorizontal alignFlexStart className="mt-1">
          <LabelInput style={{ width: "150px", fontSize: "16px" }}>
            {t("label.address")}
          </LabelInput>
          <UserInput
            value={get(mailDetails, "address")}
            onChange={(e: any) => onChangeInput(e.target.value, "address")}
            height="100%"
          />
        </FlexHorizontal>
        <FlexHorizontal alignFlexStart className="mt-1">
          <LabelInput style={{ width: "150px", fontSize: "16px" }}>
            {t("label.coverLetter")}
          </LabelInput>
          <UserTextarea
            value={get(mailDetails, "coverLetter")}
            onChange={(e: any) => onChangeInput(e.target.value, "coverLetter")}
          />
        </FlexHorizontal>
        <FlexHorizontal alignFlexStart justifySpaceBetween className="mt-1">
          <LabelInput style={{ width: "150px", fontSize: "16px" }}>
            CV <span style={{ color: "red" }}>*</span>
          </LabelInput>
          <SelectFileButton onChange={(file: File) => setCV(file)} file={cv} />
        </FlexHorizontal>
        <FlexHorizontal alignCenter justifyFlexEnd className="mt-3">
          <FlexVertical width="unset" position="relative">
            <UserCarrotButton
              isUppercase
              className={`${requestIsLoading ? "disabled" : ""} ml-2`}
              onClick={() => !requestIsLoading && onSubmit()}
            >
              {t("button.submit")}
            </UserCarrotButton>
            {requestIsLoading && <SpinLoader />}
          </FlexVertical>
        </FlexHorizontal>
      </FlexVertical>
    );
  };

  const _renderSpecificForm = () => {
    return (
      <FlexVertical flex="1" className="mt-1">
        {/* <FlexHorizontal alignFlexStart className="mt-1">
          <LabelInput style={{ width: "150px", fontSize: "16px" }}>
            {t("label.date")} <span style={{ color: "red" }}>*</span>
          </LabelInput>
          <UserDatePicker
            value={get(specificData, "date")}
            onChange={(date: string) => onChangeInputSpecific(date, "date")}
          />
        </FlexHorizontal> */}
        <h3 style={{ fontSize: "18px", margin: "10px 0" }}>
          {t("label.personalInformation")}
        </h3>
        <FlexHorizontal alignFlexStart className="mt-1">
          <LabelInput style={{ width: "150px", fontSize: "16px" }}>
            {t("label.country")} <span style={{ color: "red" }}>*</span>
          </LabelInput>
          <SelectDropdown
            options={countryOptions}
            value={get(specificData, "country")}
            onChange={(newCountry: string) =>
              onChangeInputSpecific(newCountry, "country")
            }
          />
        </FlexHorizontal>
        <FlexHorizontal alignFlexStart className="mt-1">
          <LabelInput style={{ width: "150px", fontSize: "16px" }}>
            {t("label.name")} <span style={{ color: "red" }}>*</span>
          </LabelInput>
          <UserInput
            value={get(specificData, "name")}
            onChange={(e: any) => onChangeInputSpecific(e.target.value, "name")}
          />
        </FlexHorizontal>
        <FlexHorizontal alignFlexStart className="mt-1">
          <LabelInput style={{ width: "150px", fontSize: "16px" }}>
            {t("label.surname")} <span style={{ color: "red" }}>*</span>
          </LabelInput>
          <UserInput
            value={get(specificData, "surname")}
            onChange={(e: any) =>
              onChangeInputSpecific(e.target.value, "surname")
            }
          />
        </FlexHorizontal>
        <FlexHorizontal alignFlexStart className="mt-1">
          <LabelInput style={{ width: "150px", fontSize: "16px" }}>
            {t("label.birthDate")} <span style={{ color: "red" }}>*</span>
          </LabelInput>
          <UserDatePicker
            value={get(specificData, "birthDate")}
            onChange={(date: string) =>
              onChangeInputSpecific(date, "birthDate")
            }
          />
        </FlexHorizontal>
        <FlexHorizontal alignFlexStart className="mt-1">
          <LabelInput style={{ width: "150px", fontSize: "16px" }}>
            {t("label.age")} <span style={{ color: "red" }}>*</span>
          </LabelInput>
          <UserInput
            value={get(specificData, "age")}
            onChange={(e: any) => onChangeInputSpecific(e.target.value, "age")}
          />
        </FlexHorizontal>
        <FlexHorizontal alignFlexStart className="mt-1">
          <LabelInput style={{ width: "150px", fontSize: "16px" }}>
            {t("label.sex")} <span style={{ color: "red" }}>*</span>
          </LabelInput>
          <SelectDropdown
            options={[
              { label: "Male", value: "M" },
              { label: "Female", value: "F" },
              { label: "Other", value: "O" },
            ]}
            value={get(specificData, "sex")}
            onChange={(newSex: string) => onChangeInputSpecific(newSex, "sex")}
          />
        </FlexHorizontal>
        <FlexHorizontal alignFlexStart className="mt-1">
          <LabelInput style={{ width: "150px", fontSize: "16px" }}>
            {t("label.birthPlace")} <span style={{ color: "red" }}>*</span>
          </LabelInput>
          <UserInput
            value={get(specificData, "birthPlace")}
            onChange={(e: any) =>
              onChangeInputSpecific(e.target.value, "birthPlace")
            }
          />
        </FlexHorizontal>
        {/* <FlexHorizontal alignFlexStart className="mt-1">
          <LabelInput style={{ width: "150px", fontSize: "16px" }}>
            {t("label.telFix")}
          </LabelInput>
          <UserInput
            value={get(specificData, "telephone")}
            onChange={(e: any) =>
              onChangeInputSpecific(e.target.value, "telephone")
            }
          />
        </FlexHorizontal> */}
        <FlexHorizontal className="vertical-line" />
        <h3 style={{ fontSize: "18px", margin: "10px 0" }}>
          {t("label.contactInformation")}
        </h3>
        <FlexHorizontal alignFlexStart className="mt-1">
          <LabelInput style={{ width: "150px", fontSize: "16px" }}>
            {t("label.phoneNumber")} <span style={{ color: "red" }}>*</span>
          </LabelInput>
          <UserInput
            value={get(specificData, "cell")}
            onChange={(e: any) => onChangeInputSpecific(e.target.value, "cell")}
          />
        </FlexHorizontal>
        <FlexHorizontal alignFlexStart className="mt-1">
          <LabelInput style={{ width: "150px", fontSize: "16px" }}>
            {t("label.email")} <span style={{ color: "red" }}>*</span>
          </LabelInput>
          <UserInput
            value={get(specificData, "email")}
            onChange={(e: any) =>
              onChangeInputSpecific(e.target.value, "email")
            }
          />
        </FlexHorizontal>
        <FlexHorizontal alignFlexStart className="mt-1">
          <LabelInput style={{ width: "150px", fontSize: "16px" }}>
            {t("label.currentLocation")} <span style={{ color: "red" }}>*</span>
          </LabelInput>
          <UserTextarea
            value={get(specificData, "currentLocation")}
            onChange={(e: any) =>
              onChangeInputSpecific(e.target.value, "currentLocation")
            }
          />
        </FlexHorizontal>
        <FlexHorizontal className="vertical-line" />
        <h3 style={{ fontSize: "18px", margin: "10px 0" }}>
          {t("label.educationalDetails")}
        </h3>
        <FlexHorizontal alignFlexStart className="mt-1">
          <LabelInput style={{ width: "150px", fontSize: "16px" }}>
            {t("label.education")} <span style={{ color: "red" }}>*</span>
          </LabelInput>
          <UserInput
            value={get(specificData, "education")}
            onChange={(e: any) =>
              onChangeInputSpecific(e.target.value, "education")
            }
          />
        </FlexHorizontal>
        <FlexHorizontal alignFlexStart className="mt-1">
          <LabelInput style={{ width: "150px", fontSize: "16px" }}>
            {t("label.faculty")} <span style={{ color: "red" }}>*</span>
          </LabelInput>
          <UserInput
            value={get(specificData, "faculty")}
            onChange={(e: any) =>
              onChangeInputSpecific(e.target.value, "faculty")
            }
          />
        </FlexHorizontal>
        <FlexHorizontal alignFlexStart className="mt-1">
          <LabelInput style={{ width: "150px", fontSize: "16px" }}>
            {t("label.languages")} <span style={{ color: "red" }}>*</span>
          </LabelInput>
          <UserInput
            value={get(specificData, "languages")}
            onChange={(e: any) =>
              onChangeInputSpecific(e.target.value, "languages")
            }
          />
        </FlexHorizontal>
        <FlexHorizontal className="vertical-line" />
        <h3 style={{ fontSize: "18px", margin: "10px 0" }}>
          {t("label.employmentDetails")}
        </h3>
        <FlexHorizontal alignFlexStart className="mt-1">
          <LabelInput style={{ width: "150px", fontSize: "16px" }}>
            {t("label.employment")} <span style={{ color: "red" }}>*</span>
          </LabelInput>
          <UserInput
            value={get(specificData, "employment")}
            onChange={(e: any) =>
              onChangeInputSpecific(e.target.value, "employment")
            }
          />
        </FlexHorizontal>
        <FlexHorizontal alignFlexStart className="mt-1">
          <LabelInput style={{ width: "150px", fontSize: "16px" }}>
            {t("label.location")} 1 <span style={{ color: "red" }}>*</span>
          </LabelInput>
          <UserInput
            value={get(specificData, "location1")}
            onChange={(e: any) =>
              onChangeInputSpecific(e.target.value, "location1")
            }
          />
        </FlexHorizontal>
        <FlexHorizontal alignFlexStart className="mt-1">
          <LabelInput style={{ width: "150px", fontSize: "16px" }}>
            {t("label.location")} 2
          </LabelInput>
          <UserInput
            value={get(specificData, "location2")}
            onChange={(e: any) =>
              onChangeInputSpecific(e.target.value, "location2")
            }
          />
        </FlexHorizontal>
        <FlexHorizontal alignFlexStart className="mt-1">
          <LabelInput style={{ width: "150px", fontSize: "16px" }}>
            {t("label.location")} 3
          </LabelInput>
          <UserInput
            value={get(specificData, "location3")}
            onChange={(e: any) =>
              onChangeInputSpecific(e.target.value, "location3")
            }
          />
        </FlexHorizontal>
        <FlexHorizontal alignFlexStart className="mt-1">
          <LabelInput style={{ width: "150px", fontSize: "16px" }}>
            {t("label.location")} 4
          </LabelInput>
          <UserInput
            value={get(specificData, "location4")}
            onChange={(e: any) =>
              onChangeInputSpecific(e.target.value, "location4")
            }
          />
        </FlexHorizontal>
        <FlexHorizontal alignFlexStart className="mt-1">
          <LabelInput style={{ width: "150px", fontSize: "16px" }}>
            {t("label.location")} 5
          </LabelInput>
          <UserInput
            value={get(specificData, "location5")}
            onChange={(e: any) =>
              onChangeInputSpecific(e.target.value, "location5")
            }
          />
        </FlexHorizontal>
        <FlexHorizontal alignFlexStart className="mt-1">
          <LabelInput style={{ width: "150px", fontSize: "16px" }}>
            {t("label.location")} 6
          </LabelInput>
          <UserInput
            value={get(specificData, "location6")}
            onChange={(e: any) =>
              onChangeInputSpecific(e.target.value, "location6")
            }
          />
        </FlexHorizontal>
        <FlexHorizontal className="vertical-line" />
        <h3 style={{ fontSize: "18px", margin: "10px 0" }}>
          {t("label.additionalInformation")}
        </h3>
        <FlexHorizontal alignFlexStart className="mt-1">
          <LabelInput style={{ width: "150px", fontSize: "16px" }}>
            {t("label.experiences")} <span style={{ color: "red" }}>*</span>
          </LabelInput>
          <UserTextarea
            value={get(specificData, "experience")}
            onChange={(e: any) =>
              onChangeInputSpecific(e.target.value, "experience")
            }
          />
        </FlexHorizontal>
        <FlexHorizontal alignFlexStart className="mt-1">
          <LabelInput style={{ width: "150px", fontSize: "16px" }}>
            {t("label.source")} <span style={{ color: "red" }}>*</span>
          </LabelInput>
          <UserInput
            value={get(specificData, "source")}
            onChange={(e: any) =>
              onChangeInputSpecific(e.target.value, "source")
            }
          />
        </FlexHorizontal>
        <FlexHorizontal alignCenter justifyFlexEnd className="mt-3">
          <FlexVertical width="unset" position="relative">
            <UserCarrotButton
              isUppercase
              className={`${requestIsLoading ? "disabled" : ""} ml-2`}
              onClick={() => !requestIsLoading && onSubmit()}
            >
              {t("button.submit")}
            </UserCarrotButton>
            {requestIsLoading && <SpinLoader />}
          </FlexVertical>
        </FlexHorizontal>
      </FlexVertical>
    );
  };

  return (
    <div>
      <h3 style={{ fontSize: "18px", margin: "10px 0" }}>
        {specific ? '' : t("label.candidateInformation")}
      </h3>
      {specific === 'specific' ? _renderSpecificForm() : specific === 'unspecific' ? _renderUnspecificForm() : null}
    </div>
  );
};

export default ApplyCareer;
